<template lang="pug">
	v-col(cols=12 md=2 )
		
		v-row.justify-end.align-end.mt-0
			h2.white--text.font-weight-light.d-none.d-md-block {{Pie_de_página.encuéntranos_en.texto}}
		v-row.justify-center.align-center.d-md-none  
			h2.white--text.font-weight-medium.d-md-none {{Pie_de_página.encuéntranos_en.texto}}
			//-pc
		v-row.d-none.d-md-flex.justify-end.align-end.mt-6 
			v-btn(small icon v-for='{ícono, ruta}, i in Pie_de_página.encuéntranos_en.redes_sociales'  
			@click='redirigir_a(ruta)' :key='`${i}red`').mr-4.ml-4.boton_redes
				v-icon(x-large).boton_redes.rojo--text {{ícono}}
			//-movil
		v-row.justify-center.align-center.d-md-none  
			v-btn(small icon v-for='{ícono, ruta}, i in Pie_de_página.encuéntranos_en.redes_sociales'
			 @click='redirigir_a(ruta)' :key='`${i}redM`').my-10.mr-4.ml-4.boton_redes
				v-icon(x-large).rojo--text.boton_redes {{ícono}}   
</template>
<style lang="sass">
  .boton_redes
    width: 16px
</style>
<script>
export default {
    methods: {
        redirigir_a(url){
            window.open(url, '_blank')
        },
    },
}
</script>